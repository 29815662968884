export const dropSpaces = message => {
  return message.toString().replace(/(^\s*)|(\s*$)/g, '')
}

export const dropNbsp = message => {
  var tmp

  tmp = message.split('&nbsp;')
  return dropSpaces(tmp.join(''))
}

export const dropEmoji = message => {
  let emoji = [
    '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
    '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
    '\ud83d[\ude80-\udeff]' // U+1F680 to U+1F6FF
  ]
  // eslint-disable-next-line
  const emoji2 = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig
  let pos

  emoji = new RegExp(emoji.join('|'), 'ig')

  pos = message.toString().search(emoji)

  if (pos >= 0) {
    message = message.toString().slice(0, pos)
  }
  pos = message.toString().search(emoji2)

  if (pos >= 0) {
    message = message.toString().slice(0, pos)
  }
  return message
}

export const dropLength = message => {
  return message.toString().slice(0, Math.min(message.length, window.config.questionMax))
}

/* 链式调用对象 */
function ProcessMessage (message) {
  this.message = message
}
ProcessMessage.prototype.dropSpaces = function () {
  this.message = dropSpaces(this.message)
  return this
}
ProcessMessage.prototype.dropNbsp = function () {
  this.message = dropNbsp(this.message)
  return this
}
ProcessMessage.prototype.dropEmoji = function () {
  this.message = dropEmoji(this.message)
  return this
}
ProcessMessage.prototype.dropLength = function () {
  this.message = dropLength(this.message)
  return this
}
ProcessMessage.prototype.valueOf = function () {
  return this.message
}
ProcessMessage.prototype.toString = function () {
  return this.message
}
export default ProcessMessage

<template>
  <div class="layout-panel" :class="className">
    <div class="layout-panel__header">
      <Header>
        <!-- <component :is="headerSlot" /> -->
      </Header>
    </div>
    <!-- 整体滑动 顶部吸顶 -->
    <div class="layout-panel__content" :style="contentStyle">
      <div class="layout-panel__body">
        <keep-alive :exclude="isNoCached">
          <router-view></router-view>
        </keep-alive>
      </div>
    </div>
    <div
      class="layout-panel__footer"
      v-if="footer && ui.footerHeight !== 'none'"
      :style="{
        marginTop: -ui.footerHeight - 5 + 'px',
        height: ui.footerHeight + 'px'
      }"
    >
      <component :is="footer" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from '@/components/Header'
import Message from './HeaderExtra/message.vue'
import Back from './HeaderExtra/back.vue'

export default {
  name: 'Layout',
  components: {
    Header
  },
  computed: {
    ...mapState(['ui', 'uiMode']),
    footer () {
      const meta = this.$route.meta
      if (meta) {
        return meta.footer
      }
      return null
    },
    className () {
      const meta = this.$route.meta
      return [meta ? meta.className : null, this.uiMode === 2 && 'mode-care']
    },
    headerSlot () {
      if (this.$route.name === 'Chat') {
        return Message
      } else {
        return Back
      }
    },
    contentStyle () {
      const { footerHeight } = this.ui
      if (footerHeight === 'none') {
        return { paddingBottom: 0 }
      } else {
        return { paddingBottom: Math.max(footerHeight - 5, 0) + 'px' }
      }
    },
    isNoCached () {
      const meta = this.$route
      return meta.noCache
    }
  }
}
</script>

<style lang="scss">
.chat-view {
  $footer-height: 20px;
}

.layout-panel {
  position: relative;
  z-index: 1;
  height: 100%;

  &__header {
    height: $header-height;
    overflow: hidden;
  }
  &__footer {
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    height: $footer-height;
    margin-top: -$footer-height;
    background-color: #fff;
    &.driver-fix-stacking:before {
      opacity: 0;
    }
    &:before {
      position: absolute;
      z-index: 10;
      pointer-events: none;
      content: '';
      height: 8px;
      top: -8px;
      left: 10px;
      right: 10px;
      background-color: #fff;
      border-radius: 8px 8px 0 0;
    }
  }
  &__body {
    height: 100%;
    overflow: auto;
  }
}
.layout-panel__content {
  box-sizing: border-box;
  height: 100%;
  padding-top: $header-height;
  margin-top: -$header-height;
  padding-bottom: $footer-height;
}
</style>

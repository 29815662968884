<template>
  <span class="back-button" @click="back">返回</span>
</template>

<script>
export default {
  name: 'Back',
  methods: {
    back () {
      const meta = this.$route.meta
      const prevPage = meta && meta.prevPage ? meta.prevPage : null
      if (prevPage) {
        this.$router.replace(prevPage)
      } else {
        this.$router.back()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.back-button {
  display: inline-block;
  margin-top: 2px;
  font-size: 28px;
  cursor: pointer;
}
</style>

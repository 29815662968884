import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import store from '@/store/index.js'

const $router = new VueRouter({
  mode: 'hash',
  // 公用的布局文件
  routes: [
    {
      path: '/',
      component: Layout,
      children: [
        // 对话界面
        {
          name: 'Chat',
          path: '/',
          meta: {
            footer: () => import('@/components/Footer'),
            className: 'chat-view'
          },
          component: () =>
            import(/** webpackChunkName 'Chat' */ '@/views/Chat/index.vue')
        },
        // 留言页面
        {
          name: 'VisitorBook',
          path: '/message',
          component: () =>
            import(
              /** webpackChunkName 'VisitorBook' */ '@/views/VisitorBook/index.vue'
            ),
          meta: {
            prevPage: {
              name: 'Chat'
            },
            auth: true
          }
        },
        // 留言回复页面
        {
          name: 'VisitorReply',
          path: '/message/:id',
          props: true,
          meta: {
            className: 'visitor-view',
            prevPage: {
              name: 'VisitorBook'
            },
            auth: true
          },
          component: () =>
            import(
              /** webpackChunkName 'VisitorBook' */ '@/views/VisitorBook/reply.vue'
            )
        },
        // 历史留言查看页面
        {
          name: 'VisitorView',
          path: '/message/closed/:id',
          props: true,
          meta: {
            className: 'visitor-view',
            prevPage: {
              name: 'VisitorBook',
              query: {
                t: 'history'
              }
            },
            auth: true
          },
          component: () =>
            import(
              /** webpackChunkName 'VisitorBook' */ '@/views/VisitorBook/state.vue'
            )
        },
        // 测试页面
        {
          name: 'Test',
          path: '/test',
          props: true,
          meta: { },
          component: () =>
            import(
              /** webpackChunkName 'Test' */ '@/views/Test/index.vue'
            )
        }
      ]
    },
    // 旧版本的问答页面
    {
      name: 'V1',
      path: '/chat',
      component: () => import(/** webpackChunkName 'V1' */ '@/views/V1.vue')
    }
  ]
})

/**
 * 这里重写 vue-router 的跳转方法
 * ios 把所有路径的跳转都用 replace 重写 防止出现 底部的导航栏
 */
if (window.__SYSTEM === 'ios') {
  $router.push = $router.replace
}

/**
 * 定义找不到的路由地址全部定向到首页对话页面
 * 对维护中页面没有影响
 */
$router.beforeEach((to, from, next) => {
  const { meta } = to
  if (to.matched.length < 1) {
    // 未匹配到任何的路由
    next({ name: 'Chat' })
  }
  if (meta.auth && !store.getters.isLogined) {
    next({ name: 'Chat' })
  }
  next()
})

export default $router

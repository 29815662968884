import axios from 'axios'
import qs from 'qs'
import { Notify } from 'vant'
// import store from '../store'
// var cookies = require('vue-cookies')
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.responseType = 'json'
// 创建一个 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000 // 请求超时时间
  // paramsSerializer (params) { // 序列化函数
  //   qs.stringify(params)
  // }
})

// POST传参序列化
service.interceptors.request.use(
  (config) => {
    /**
     * 全局追加参数
     */
    function appendData (data = {}) {
      const attrs = ['orgCode', 'platform', 'version']
      for (const i in attrs) {
        const attr = attrs[i]
        data[attr] = data[attr] || window.config[attr]
      }
    }
    if (config.method === 'post') {
      config.data = config.data || {}
      appendData(config.data)
      config.data = qs.stringify(config.data)
    } else {
      config.params = config.params || {}
      appendData(config.params)
    }

    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const data = response.data
    if (data.code === 0) {
      return Promise.resolve(data)
    } else {
      Notify({ type: 'danger', message: data.msg || '网络错误' })
      return Promise.reject(data)
    }
  },
  (error) => {
    Notify({ type: 'danger', message: error.msg || '网络错误' })
    return Promise.reject(error)
  }
)

export default service

import { render, staticRenderFns } from "./staffExit.vue?vue&type=template&id=0621436c&scoped=true&"
import script from "./staffExit.vue?vue&type=script&lang=js&"
export * from "./staffExit.vue?vue&type=script&lang=js&"
import style0 from "./staffExit.vue?vue&type=style&index=0&id=0621436c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0621436c",
  null
  
)

export default component.exports
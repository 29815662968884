<template>
  <div>
    <div class="poper-content-title">
      <i class="sys-model"></i>
    </div>
    <div class="poper-content__body">
      您将退出当前人工坐席服务，转入智能问答，是否继续？
    </div>
    <div class="poper-content__footer">
      <div class="poper-button__default" @click="confirm">
        是
      </div>
      <div class="poper-button__default btn-border" @click="cancle">
        否
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StaffExit',
  methods: {
    confirm () {
      this.$emit('confirm')
    },
    cancle () {
      this.$emit('cancle')
    }
  }
}
</script>

<style lang="scss" scoped>
.poper-content__body {
  padding: 0.2rem 0.15rem 0.15rem;
  font-weight: bold;
}

.poper-content__footer {
  margin-top: 0;
  padding: 0 0.2rem 0.2rem 0.2rem;
}
</style>

<template>
  <div class="poper-panel__total" :class="theme">
    <div class="poper-panel__content" :style="styleObj">
      <component :is="content" @cancle="cancle" @confirm="confirm"></component>
    </div>
  </div>
</template>

<script>
export default {
  name: 'confirm',
  props: {
    content: {
      type: Object
    },
    styleObj: {
      type: Object
    },
    theme: {
      type: String
    }
  },
  methods: {
    cancle (data = false) {
      this.$close(data)
    },
    confirm (data = true) {
      this.$close(data)
    }
  }
}
</script>

// import typeOf from '@/utils/typeof'
// 定义会话内容的id
let bubbleId = 1

// 背景文字  -1;
// 欢迎语和机器人聊天文字 0;
// 首页推荐业务列表 1; 【无】
// 首页推荐 问题列表 2;
// 返回数据为列表 3;
// 进一步查询的类型 4;
// 首页渠道名称 5;
// 首页业务 6;
// 知识库 7;
// 您可能关注下列相关问题 8;
// 列表 9;
// 知识库内容 10;
// 知识库 11;
// 知识库内容页分类 12;
// 首页推荐引导语 13;
// 相关问引导语 14;
// 建议问引导语 15;
// 相关问建议问话术 16;
// 栏目 17;
// 热点咨询话术 18;
// 考试列表 19;
// 下载列表 20;
// 地域选择 21;
// 联系地址 22;
// 考试二级问题 23;
// 朗读语音 9999;
// 有无帮助 t;
// 转人工 t;
/**
 * ===============================================
*/
// 相关业务 12
// 首页推荐引导语 13
// 相关问引导语 14
// 建议问引导语 15
// 相关问建议问话术 16
// 栏目 17
// 热点咨询话术 18
// 考试列表 19
// 下载列表 20
// 地域选择 21
// 联系地址 22
// 子级问题列表 23
// 朗读语音 9999
// 有无帮助 t
// 转人工 t

// 定义每个类型会话的数据结构
const dataInterface = {
  default (data) {
    data.id = bubbleId++
    return data
  },
  0 (data) {
    if (data.from === 2) {
      return data
    }
    // html标签只针对文字信息进行处理，忽略html属性的处理
    function matchHtml (input, result, hanlder) {
      const start = /<[^>]+>/i
      const end = /<\/[a-z]+>/i
      const find = start.exec(input)
      if (find) {
        const nextString = input.slice(find.index + find[0].length)
        result += find[0]
        // 找到结束为止
        const tryStart = start.exec(nextString)
        const tryEnd = end.exec(nextString)
        let textEnd
        if (tryStart && tryEnd) {
          textEnd = Math.min(tryStart.index, tryEnd.index)
        } else if (tryStart) {
          textEnd = tryStart.index
        } else if (tryEnd) {
          textEnd = tryEnd.index
        }
        const textString = nextString.slice(0, textEnd)
        textString && (result += hanlder(textString))
        if (nextString) {
          return matchHtml(nextString, result, hanlder)
        } else {
          return result
        }
      } else {
        result += hanlder(input)
        return result
      }
    }
    data.content = matchHtml(data.content, '', function (textContent) {
      const phoneMatcher = /((0\d{2,3}-?\d{7,8})|(12[\d]{3}))/g
      return textContent.replace(phoneMatcher, '<a class="" href="tel:$1">$1</a>')
    })
    return data
  },
  13 () {
    return false
  },
  16 () {
    return false
  },
  18 (data) {
    data.id = bubbleId++
    data.content = [{
      desc: data.content
    }]
    return data
  }
}

// 创建数据的方法
export const buildData = (data) => {
  let builder = dataInterface[data.type]

  builder = builder || dataInterface.default
  return builder(data)
}

import Vue from 'vue'
import vuePicturePreview from 'vue-picture-preview'

let elemInstance

export default function preview (src) {
  vuePicturePreview.props.list.required = false
  vuePicturePreview.props.list.default = () => [
    {
      msrc: src,
      src: src
    }
  ]
  if (!elemInstance) {
    const wrapper = document.createElement('div')
    document.body.appendChild(wrapper)
    console.log(vuePicturePreview)
    elemInstance = new Vue(vuePicturePreview)
    elemInstance.$props.options = {
      fullscreenEl: false,
      bgOpacity: 0.8
    }
    elemInstance.$mount(wrapper)
  }
  // elemInstance.$props.list = [
  //   {
  //     msrc: src,
  //     src: src
  //   }
  // ]
  elemInstance.show(0)
}

<!--
 * @Author: 刘春宇 aigoubuluo@126.com
 * @Date: 2022-10-10 10:58:31
 * @LastEditors: 刘春宇 aigoubuluo@126.com
 * @LastEditTime: 2022-11-07 13:41:58
 * @FilePath: /AI-APP-henan/src/layout/HeaderExtra/message.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div :class="uiMode === 1 ? 'message-slot-general' : 'message-slot'">
    <!-- 关爱版/普通版切换按钮 -->
    <div
      class="mode-switcher"
      :class="uiMode === 1 && 'mode-care'"
      @click="switchMode"
    >
      {{ uiMode === 1 ? '进入老年版' : '进入普通版' }}
    </div>
    <van-badge
      class="message-button"
      @click.native="toVisitor"
      v-if="isLogined"
    >
      <van-icon class="message" :name="message"></van-icon>
    </van-badge>
  </div>
</template>

<script>
import { Icon, Badge } from 'vant'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'message',
  data () {
    return {
      message: require('@/assets/icons/icon-message.png')
    }
  },
  computed: {
    ...mapGetters(['isLogined', 'uiMode']),
    ...mapState(['uiMode'])
  },
  components: {
    [Icon.name]: Icon,
    [Badge.name]: Badge
  },
  methods: {
    switchMode (e) {
      if (
        e.target.classList &&
        e.target.classList.value.indexOf('driver-highlighted-element') >= 0
      ) {
        return
      }
      this.$store.commit('switchMode')
    },
    toVisitor () {
      this.$router.push({ name: 'VisitorBook' })
    }
  }
}
</script>

<style lang="scss" scoped>
.message-slot-general {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-right: 8px;
  height: 30px;
  line-height: 30px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  .mode-switcher {
    margin-top: 2px;
    line-height: 1.2em;
    &.mode-care:before {
      float: left;
      content: '';
      width: 1.0em;
      height: 1.4em;
      margin-right: 4px;
      margin-top: 2px;
      background: url('../../assets/icons/icon-care.png') no-repeat center top;
      background-size: contain;
    }
  }
  .message-button {
    font-size: 125%;
    margin-left: 15px;
  }
}
.message-slot {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-right: 8px;
  height: 30px;
  line-height: 30px;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  .mode-switcher {
    margin-top: 2px;
    line-height: 1.2em;
    &.mode-care:before {
      float: left;
      content: '';
      width: 1.0em;
      height: 1.4em;
      margin-right: 4px;
      margin-top: -2px;
      background: url('../../assets/icons/icon-care.png') no-repeat center top;
      background-size: contain;
    }
  }
  .message-button {
    font-size: 125%;
    margin-left: 15px;
    .message{
      margin-top: 5px;
    }
  }
}
</style>

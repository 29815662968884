import Vue from 'vue'
import Vuex from 'vuex'
import typeOf from '@/utils/typeof'
import Staff from '@/plugins/staff/staffServer'
import staffExit from '@/components/Dialogs/staffExit'
import StaffYawp from '@/components/Dialogs/staffYawp'
import switcher from '@/plugins/staff/role.switcher'
import { confirm } from '@/components/Dialogs'
import cache from '@/plugins/storage'
import simpleIngoreTypes from '@/views/Chat/simpleIngoreTypes'
// 引入静态的数据测试样式
// import mockMessages from '@/mock/message.js'

// 引入api方法
import { initApp, askQuestion, getRobotConfig } from '@/api/chat'
// 数据格式化的方法
import { buildData } from '@/components/Bubbles/interface'

// loading bubble list
const loadings = []
Vue.use(Vuex)

/**
 * 定义 人工客服 和 智能客服 的提问方式
 */
const messageSender = {
  async robot (message) {
    const store = this
    const {
      id,
      source,
      appId,
      insureArea,
      insureAreaCode,
      chatId,
      platform,
      orgCode,
      token,
      theme
    } = store.state.appConfig
    const { sid, errorCount } = store.state.params
    const navigationId = store.state.navigation ? store.state.navigation.id : ''
    if (!message.examId && store.state.exam) message.examId = store.state.examId
    if (!message.examName && store.state.exam) {
      message.examName = store.state.examName
    }
    if (!message.navigationName && store.state.navigationName) {
      message.navigationName = store.state.navigationName
    }
    if (message.dataType && message.dataType === 'relBusiness-selected') {
      await askQuestion({
        id,
        source,
        appId,
        insureArea,
        insureAreaCode,
        chatId,
        platform,
        orgCode,
        token,
        theme,
        sid,
        errorCount,
        sceneId: store.state.sceneId,
        navigationId,
        ...message
      })
      return
    }
    // 提问的问题提交到会话列表
    // 追加type，省去每个提问入口都增加 type 字段
    message.type = 0
    store.commit('appendBubble', buildData(message))
    // 追加一个loading数据
    const loading = buildData({
      type: 'loading'
    })
    loadings.push(loading)
    store.commit('appendBubble', buildData(loading))
    // 获取答案并且添加到列表
    try {
      let {
        data,
        applyId,
        chatId: chatIdRes,
        sid: sidRes,
        errorCount: errorCountRes
      } = await askQuestion({
        chatId,
        sid,
        errorCount,
        platform,
        orgCode,
        token,
        navigationId,
        sceneId: store.state.sceneId,
        ...message
      })
      // 把参数更新到 store.params 上面
      store.commit('storeRemoteParams', {
        chatId: chatIdRes,
        sid: sidRes,
        errorCount: errorCountRes
      })

      // 为每个回答追加上 applyId，反馈答案有无帮助反馈时会使用。过滤出文本回答用来转换类型
      data = data.filter((item) => Object.keys(item).length > 0)
      data.forEach((item, index) => {
        console.log(item)
        item.dataType = message.dataType
        // 为非空消息增加 applyId
        Object.keys(item).length && (item.applyId = applyId)
        // 修改回答消息类型的 type 标记
        if (data[index + 1] && data[index + 1].type === 23) {
          item.style = 25
        } else if (item.type === 0 && item.content) {
          item.type = 888
        }

        // 如果有需要反馈的消息增加一个反馈气泡 t 是帮助反馈标记
        if (item.t === true) {
          data.push({
            type: 'feedback',
            applyId
          })
        }
        if (item.a === true) {
          store.commit('updateLevel', 4)
        }
      })

      // 消息列表删除 loading 气泡数据
      const loading = loadings.shift()
      store.commit('deleteBubble', loading)

      // 修改回答的文本的类型
      console.log(data)
      store.commit('appendBubble', data)
    } catch (error) {
      // loading 提花错误状态
      // 消息列表删除 loading 气泡数据
      const loading = loadings.shift()
      store.commit('deleteBubble', loading)
      store.commit('appendBubble', {
        type: 0,
        from: 1,
        content: error.toString()
      })
    }
  },
  staff (message) {
    const store = this

    store.commit('appendBubble', buildData(message))
    Staff.post(message.question)
  }
}

const store = new Vuex.Store({
  state: {
    robotConfig: {
      name: '智能客服',
      icon: require('@/assets/demo/logoo.png')
    }, // 机器人的配置
    appConfig: window.config, // 全局的配置
    chatId: null, // 会话id
    messages: [], // 对话管理： 二维数组
    voice: [],
    navigation: null, // 栏目
    exam: [], // 栏目考试
    examId: '',
    examName: '',
    sceneId: '',
    level: 0,
    params: {},
    ui: {
      footerHeight: 'auto'
    },
    uselessCount: 0,
    related: '您可能还关注',
    suggest: '您可能关注下列建议问题',
    examChoose: '请选择您要咨询的类别',
    examChooseVoice: '',
    freshNeed: false,
    chattingType: 'robot', // robot: 智能客服； staff： 人工客服
    staffFeedback: false,
    uiMode: 1, // 1 普通版；2 关爱版
    isVoiceInitial: false
  },
  getters: {
    // 获取当前朗读的语音资源路径
    displayVoice (state) {
      return state.voice[state.voice.length - 1]
    },
    // 消息数据
    messages (state) {
      window.config = window.config || {}
      window.config.version = state.uiMode === 2 ? 'old' : ''
      if (state.uiMode === 2) {
        return state.messages.filter(
          (item) => simpleIngoreTypes.indexOf(item.type) < 0
        )
      } else {
        return state.messages
      }
    },
    // 考试数据
    exam (state) {
      console.log('get exam in exam getter')
      console.log(state.exam)
      if (state.exam.length < 1) {
        return null
      } else {
        let isLevel3 = false
        let examHit

        // 返回相邻的 level == 3 的嘴前面的一个
        for (let i = state.exam.length - 1; i >= 0; i--) {
          const exam = state.exam[i]
          // 第一次找到level3的考试数据
          if (!isLevel3 && exam.level === 3) {
            isLevel3 = true
          } else if (isLevel3 && exam.level !== 3) {
            // 由尾部向前找到的数据
            if (i === state.exam.length - 1) {
              examHit = exam
            } else {
              examHit = state.exam[i + 1]
            }
            break
          }
        }
        if (!examHit) {
          examHit = state.exam
            .filter((item) => item.level === 1 || item.level === 2)
            .pop()
        }
        console.log(examHit)
        return examHit
      }
    },
    // 获取user数据
    userData ({ user }) {
      const config = window.config
      return {
        userId: config.id,
        userName: config.userName,
        insureArea: config.insureArea,
        insureAreaCode: config.insureAreaCode,
        phoneNumber: config.phoneNumber
      }
    },
    // 是否已经登陆
    isLogined () {
      const config = window.config
      return !!(config.id && config.userName)
    }
  },
  mutations: {
    // 更新对话列表信息，向列表中添加数据
    appendBubble ({ messages, voice, appConfig, uselessCount }, message) {
      if (typeOf(message) === 'object') {
        // 相关问引导语
        if (message.type === 14) {
          this.state.related = message.content
          return
        }
        // 相关问引导语
        if (message.type === 15) {
          this.state.suggest = message.content
          return
        }
        // 考试选择引导语
        if (message.type === 19) {
          this.state.examChoose = message.content
          this.state.examChooseVoice = message.data
          return
        }
        // 追加消息
        message.type === 9999 ? voice.push(message) : messages.push(message)
        // 无帮助计数
        if (
          message.content &&
          message.content.indexOf(appConfig.staticMessages.NO_ANSWER_TXT) > -1
        ) {
          uselessCount++
        }
      } else if (typeOf(message) === 'array') {
        const msgList = []

        message.forEach((item) => {
          if (item && Object.keys(item).length) {
            // 追加消息
            item.type === 9999
              ? voice.push(item)
              : msgList.push(buildData(item))
            // 无帮助计数
            if (
              item.content &&
              item.content.indexOf(appConfig.staticMessages.NO_ANSWER_TXT) > -1
            ) {
              uselessCount++
            }
            // 如果 type 是 0 并且存在 f：true 的标记 需要自动切换人工客服
            if ((item.type === 0 || item.type === 888) && item.f) {
              switcher.switchToStaff.call(this)
            }
          }
        })

        messages.push(msgList)
      }
    },
    // 兼容旧数据
    insertMessage (state, data) {
      typeOf(data) === 'array'
        ? data.forEach((message) => store.commit('appendBubble', message))
        : store.commit('appendBubble', data)
    },
    // footer 更新布局的变化
    updateLayout (state, layout) {
      state.ui.footerHeight = layout.footerHeight
    },
    // 初始化应用
    clearCacheData (state) {
      state.messages.splice(0)
      state.voice.splice(0)
      state.navigation = ''
      state.exam.splice(0)
      state.examId = ''
      state.uselessCount = 0
      state.examName = ''
      state.sceneId = ''
      state.level = 0
    },
    // 删除消息的方法: 仅用来删除loading气泡
    deleteBubble: (function () {
      let start = 0
      let uiMode
      return function (state, data) {
        const length = state.messages.length

        if (uiMode !== state.uiMode) {
          start = 0
        }
        uiMode = state.uiMode
        for (; start < length; start++) {
          const current = state.messages[start]

          if (typeOf(current) === 'object' && current.id === data.id) {
            state.messages.splice(start, 1)
            return
          }
        }
      }
    })(),
    // 更新会话id
    setChatId ({ chatId }, data) {
      chatId = data
    },
    // 更新栏目
    storeNavigation (store, data) {
      store.navigation = data
    },
    // 更新当前的场景值
    updateExam (state, data) {
      if (data && data.level) {
        state.level = data.level
      }
      if (data === null) {
        state.exam.splice(0)
      } else if (data === undefined) {
        state.exam.pop()
      } else {
        state.exam.push(data)
      }
    },
    // 存储必要的接口参数
    storeRemoteParams (state, data) {
      Object.keys(data).forEach((key) => {
        state.params[key] = data[key]
      })
    },
    updateLevel (state, data) {
      state.level = data
    },
    updateExamName (state, data) {
      state.examName = data
    },
    updateExamId (state, data) {
      console.log('state.examId:' + data)
      state.examId = data
    },
    // 更细sceneId
    updateSceneId (state, data) {
      state.sceneId = data
    },
    updateFreshNeed (state, data) {
      state.freshNeed = data
    },
    switchRole (state, type) {
      if (state.chattingType === type) {
        return false
      }
      state.chattingType = type
    },
    setStaffFeedback (state, payload) {
      state.staffFeedback = payload
      console.log(
        '当前会话' + (state.staffFeedback ? '' : '不') + '需要客户反馈满意度'
      )
    },
    setUserData (state, data) {
      state.user = data
      cache.set('user', data)
    },
    // 切换关爱版和普通版
    switchMode (state, data) {
      loadings.splice(0)
      if (data && typeof data === 'number') {
        state.uiMode = data
      } else {
        const uiMode = store.state.uiMode
        state.uiMode = uiMode === 1 ? 2 : 1
      }
      cache.set('uiMode', state.uiMode)
    },
    updateVoiceState (state) {
      state.isVoiceInitial = true
    }
  },
  actions: {
    // 获取机器的基本配置信息
    async getRobotConfig (store) {
      const orgCode = store.state.appConfig.orgCode
      const result = await getRobotConfig({
        orgCode
      })
      if (result.code === 0) {
        const data = result.data
        const config = data.find((item) => item.type === 5)
        const user = data.find((item) => item.user)
        // 缓存用户的信息
        user.user.orgCode = user.user.orgCode || orgCode
        user && store.commit('setUserData', user.user)
        store.state.robotConfig = config || {}
      }
    },
    // 获取欢迎语
    async initApp (store) {
      const { chatId, platform, orgCode, token, theme } = store.state.appConfig
      try {
        const { data } = await initApp({
          chatId,
          platform,
          orgCode,
          token,
          theme
        })
        // 初始换清空旧的聊天记录
        data.forEach((item) => store.commit('appendBubble', buildData(item)))
        // 更新会话id
        // store.commit('setChatId', chatId)
      } catch (e) {
        console.log(e)
      }
    },
    // 问答 - 提问
    async askQuestion (store, message) {
      console.warn(
        'store处理消息交互，当前的客服类型是',
        store.state.chattingType
      )
      console.log('dataType:' + message.dataType)
      if (store.state.chattingType === 'staff' && message.onlyRobot) {
        /* 人工客服状态询问标准问建议问，询问切换到智能客服 */
        const isSwitcher = await confirm({
          content: staffExit
        })

        if (isSwitcher) {
          // 需要切换到智能客服
          store.commit('switchRole', 'robot')
          Staff.close()
        } else {
          return false
        }
      }
      messageSender[store.state.chattingType].call(store, message)
    },
    // 人工客服服务质量反馈
    async StaffFeedbackScore (store, data) {
      let satiscomment = ''

      /* 接收分数，判断分数多少给出理由选择弹框 */
      if (data > 3) {
        satiscomment = await confirm({
          content: StaffYawp,
          theme: 'pop-reason'
        })
      }

      Staff.feedback({
        satislevel: data,
        satiscomment
      })

      /* 推送感谢信息 */
      store.commit('insertMessage', {
        from: 0,
        type: -1,
        content: '感谢您的反馈',
        dataType: 'sysmsg'
      })
    }
  }
})

export default store

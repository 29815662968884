/**
 * Created by vonsh on 2019/3/25
 */
import { confirm } from '@/components/Dialogs'
import Staff from '@/plugins/staff/staffServer'
import staffExit from '@/components/Dialogs/staffExit'
import Spliter from './staff.libs.splitter'
import ProcessMessage from '@/utils/message.filter'
import FeedbackEntry from '@/components/Dialogs/feedbackEntry'
import HelplessEntry from '@/components/Dialogs/helplessEntry'
const CONSTS = window.config.staticMessages
/*
* 定义人工客服的接受消息的处理方法
* */
const staffReciever = (function () {
  const staffRecieverSplitter = new Spliter()
  // 图片的处理
  const imageHander = function (data, message, global, processer) {
    // 对图片数据进行格式化
    const imgTester = /<img.*\/[\s]?>/
    const replacer = /(\/res\/image.html\?id=[a-zA-Z\d/\\]+)/g
    const host = global.stuffService.host.split('/im/').shift()
    if (imgTester.test(message)) {
      return message.replace(replacer, host + '$1')
    }
    if (data.messageType === 'image') {
      return global.stuffService.host.split('/im/').shift() + data.message
    }
    return processer ? processer.dropNbsp().dropSpaces().message : message
  }
  /*
  * 如果没有客服在线，推送我要提问业务，并断开客服聊天
  * */
  staffRecieverSplitter.addRule(function (message) {
    return message.indexOf('当前无人工坐席在线') > -1
  }, function (message, data, params) {
    Staff.close()
    this.commit('switchRole', 'robot')
    console.log('no staff online, auto disconnect.')
    // 把人工客服消息插入列表
    this.commit('appendBubble', [{
      from: 0,
      type: params.type,
      t: false,
      content: (function (global) {
        return imageHander(data, message, global)
      })(window.config)
    }])
  })

  /*
  * 如果客服全忙碌，提示“稍后重试”
  * */
  staffRecieverSplitter.addRule(function (message) {
    return message.indexOf('坐席全忙，在您之前，还有 <span id=\'queneindex\'>') === 0
  }, function (message, data, params) {
    /*
    * 切换具体的角色
    * */
    this.commit('switchRole', 'staff')
    // {
    //   text: '坐席排队中...，',
    //   action: null
    // },
    this.commit('insertMessage', {
      from: 0,
      type: 'anchor',
      content: [{
        text: message,
        type: 'html'
      }, {
        text: this.state.robotConfig.name,
        action: async function (target) {
          if (this.state.chattingType === 'robot') {
            return false
          }
          const isSwitcher = await confirm({
            content: staffExit
          })
          if (isSwitcher) {
            Staff.close()
            target.commit('switchRole', 'robot')
            target.commit('insertMessage', {
              from: 0,
              type: -1,
              content: CONSTS.STAFF2ROBOT_TIPS
            })
          }
        }
      }, {
        text: '随时帮您解答',
        action: null
      }]
    })
  })

  /*
  * 判断回复是否含有有意义的字符串，没有就返回
  * */
  staffRecieverSplitter.addRule(function (message) {
    const processer = new ProcessMessage(message)
    return processer.dropNbsp().dropSpaces().message.length < 1
  }, function (message, data, params) {
    return false
  })

  /*
  * 正常消息推送
  * */
  staffRecieverSplitter.addRule(function (message) {
    return true
  }, function (message, data, params) {
    /*
    * 切换具体的角色
    * */
    this.commit('switchRole', 'staff')

    const processer = new ProcessMessage(message)

    this.commit('insertMessage', {
      from: 0,
      type: params.type,
      t: false,
      content: (function (global) {
        return imageHander(data, message, global, processer)
      })(window.config)
    })
  })

  let prevTimestamp = 0
  let prevData = null

  return function (data, type) {
    const currentTime = +new Date()

    /*
    * 判断在800毫秒内发送同样的消息，认为是无效的，防止出现重复消息的bug
    * */
    console.warn(currentTime - prevTimestamp < 800 && data.message === prevData)
    console.log(currentTime - prevTimestamp, data.message, prevData)
    if (currentTime - prevTimestamp < 800 && data.message === prevData) {
      prevTimestamp = currentTime
      return false
    }
    prevData = data.message
    prevTimestamp = currentTime
    type = type === 'status' ? '-1' : 0
    if (data.messageType === 'image') {
      type = 10
    }
    /*
    * 为人工客服的图片设置正确的消息类型
    * */
    staffRecieverSplitter.bindContext(this).exec(data.message, data, {
      type
    })
  }
})()

/*
* 人工客服断开回调方法
* */
const staffStoper = function (type, config) {
  /*
  * 提示人工客服链接断开
  * type: 0 客服人工手动关闭
  *       1 系统定时器自动关闭
  *       2 用户切换标准问操作自动切换智能客服
  * */
  const messages = []

  if (!(type === 2)) {
    this.commit('switchRole', 'robot')
    console.warn('remote disconnect' + (type === 1 ? 'timeout' : 'end'))

    // 判断是否需要反馈
    if (this.state.staffFeedback) {
      // 插入反馈的消息盒子
      messages.push({
        type: 'commit',
        config: config
      })
    }

    messages.unshift({
      type: -1,
      content: type === 1 ? '非常抱歉，由于您长时间没有提问，已经与客服人员的断开连线，如果需要再连线我们吧！' : '人工客服结束服务',
      config: config,
      dataType: 'sysmsg'
    })
    this.commit('insertMessage', messages)
  }
}

/*
* 反馈结果判断分支走向
* */
export const feedbackSwitcher = function (cause, condition) {
  const self = this

  const hanlder = {
    async feedback (condition) {
      if (condition === 1) {
        // vue.toast({
        //   message: '感谢您的反馈，能帮到您我很开心！',
        //   icon: require('../assets/images/success.png')
        // })
        alert('感谢您的反馈，能帮到您我很开心！')
      } else {
        const hasConfirmed = await confirm({
          content: FeedbackEntry,
          styleObj: {
            width: '4rem',
            marginLeft: '-2rem'
          }
        })

        if (hasConfirmed) {
          // 需要转人工
          Staff.create(function () {
            staffReciever.apply(self, arguments)
          }, function () {
            staffStoper.apply(self, arguments)
          })
        } else {
          // do nothing
        }
      }
    }
  }

  hanlder[cause](condition)
}

/*
* 智能客服无结果
* */
export const noAnswerSwitcher = async function () {
  const self = this

  if (await confirm({
    content: HelplessEntry
  })) {
    // 需要转人工
    Staff.create(function () {
      staffReciever.apply(self, arguments)
    }, function () {
      staffStoper.apply(self, arguments)
    })
  }
}

/**
 * 自动转入人工客服
 */
export const switchToStaff = async function () {
  const self = this
  const hasConfirmed = await confirm({
    content: HelplessEntry
  })
  if (!hasConfirmed) {
    return false
  }
  this.commit('switchRole', 'staff')

  Staff.create(function () {
    staffReciever.apply(self, arguments)
  }, function () {
    staffStoper.apply(self, arguments)
  })
}

export default {
  feedbackSwitcher,
  noAnswerSwitcher,
  switchToStaff
}

import db from './util.db'
/**
 * 写数据的时候内存保留同时实时的添加的cookie中
 * 清除分为 缓存清除 和 cookie 清除
 */
class Cache {
  construct () {
    db.defaults({
      mapState: {}
    }).write()
  }

  /**
   * 设置数据
   */
  set (path, value) {
    return db.set(path, value).write()
  }

  /**
   * 是否存在数据
   */
  has (path) {
    return db.has(path).value()
  }

  /**
   * 获取数据
   */
  get (path) {
    return db.get(path).value()
  }

  /**
   * 清除缓存
   */
  unset (path) {
    return db.unset(path).write()
  }
}

export default new Cache()

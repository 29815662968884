<template>
  <div class="helpless-wrapper">
    <div class="poper-content-title">
      抱歉没有找到答案
      <i class="sys-model"></i>
    </div>
    <div class="poper-content__body">
      {{ uselessTip }}
    </div>
    <div class="poper-content__footer">
      <!-- <div class="poper-button__default" @click="message">
        我要提问
      </div> -->
      <div class="poper-button__default" @click="confirm">
        转人工
      </div>
      <div class="poper-button__default btn-border" @click="cancle">
        不需要
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatFeedbackResponse',
  computed: {
    uselessTip () {
      return this.$store.state.appConfig.staticMessages.USELEE_TIP
    }
  },
  methods: {
    cancle () {
      this.$emit('cancle')
    },
    confirm () {
      this.$emit('confirm')
    }
    // message () {
    //   window.location.href =
    //     'http://rssmf.jsszhrss.gov.cn/web/weixin/comp/mgmt/f01s/f01s01/f01s0101.action'
    // }
  }
}
</script>

<style lang="scss" scoped>
.helpless-wrapper {
  .poper-content__body {
    padding: 0 0.2rem;
  }
  .poper-content__footer {
    padding: 0 0.2rem 0.3rem;
  }
}
</style>

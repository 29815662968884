<template>
  <div>
    <div class="poper-content-title">
      亲，我哪里做的不好呢？
      <i class="sys-model"></i>
    </div>
    <div class="poper-content__body">
      <ul style="margin: 0; padding: 0;">
        <li
          v-for="(item, idx) in reasons"
          :key="idx"
          :class="{ selected: item.id === selected }"
          @click="getReason(item)"
        >
          {{ item.message }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'staffYawp',
  data () {
    return {
      selected: -1,
      reasons: [
        {
          id: 1,
          message: '客服人员的业务能力不够'
        },
        {
          id: 2,
          message: '客服人员的服务态度不好'
        },
        {
          id: 3,
          message: '不能得到及时服务'
        },
        {
          id: 4,
          message: '其他'
        }
      ]
    }
  },
  methods: {
    getReason (item) {
      this.selected = item.id
      this.$emit('confirm', item.message)
    }
  }
}
</script>

<style lang="scss">
.pop-reason {
  .poper-panel__content {
    -webkit-background-size: 6.5rem auto;
    background-size: 6.5rem auto;
    background-position: center -0.5rem;
  }
  .poper-content__body {
    margin-top: 0.32rem;
    margin-bottom: 0.36rem;
    li {
      list-style: none;
      padding: 0;
      font-size: 0.16rem;
      color: #22272a;
      line-height: 2.5em;
      margin-bottom: 0.1rem;
      &.selected {
        background: #54b5fd;
        color: #fff;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>

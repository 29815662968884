<template>
  <div id="header">
    <div :class="uiMode === 1 ? 'header-content-general' : 'header-content'">
      <div class="app-extra">
        <slot />
      </div>
      <div class="app-symbol">
        <van-image :src="robotConfig.icon || logo" fit="cover"></van-image>
      </div>
      {{ robotConfig.name }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { Image } from 'vant'
export default {
  name: 'AppHeader',
  data () {
    return {
      logo: require('@/assets/demo/logo_red.png')
    }
  },
  computed: {
    ...mapGetters(['isLogined', 'uiMode']),
    ...mapState(['appConfig', 'robotConfig', 'uiMode'])
  },
  components: {
    [Image.name]: Image
  },
  watch: {
    robotConfig: {
      deep: true,
      handler (config) {
        document.title = config.name
      }
    }
  },
  methods: {
    // 换主题
    theme (type) {
      // this.$store.commit('upDate', { themeType: type })
      window.document.documentElement.setAttribute('data-theme', type)
    }
  },
  mounted () {
    // this.robotName = this.appConfig.orgCode === 'sichuan' ? '四川考试网智能客服' : '人社部人事考试中心智能客服'
    // console.log(this.$cookie)
    // if (this.$cookie.get('theme') !== undefined) {
    // console.log(this.$cookie.get('theme'))
    // }
    this.theme('dark')
  }
}
</script>

<style lang="scss" scoped>
$logo-size: 50px;
#header {
  position: relative;
  z-index: 10;
  padding-left: 10px;
  padding-right: 10px;
  &:before {
    display: block;
    height: 5px;
    margin-top: 5px;
    @include background_color('theme-color-1');
    content: '';
    border-radius: 5px;
    overflow: hidden;
  }
  .header-content-general {
    padding-top: 20px;
    padding-right: 140px;
    padding-bottom: 22px;
    padding-left: $logo-size + 28px;
    font-size: 28px;
    @include font_color('theme-color-2');
    font-weight: bold;
    line-height: $logo-size;
    @include clear();

    .app-symbol {
      float: left;
      width: $logo-size;
      height: $logo-size;
      margin-left: -($logo-size + 20px);
      border-radius: 6px;
      overflow: hidden;
      text-align: center;
      position: relative;
      background-color: rgba(0, 64, 152, 0.2);
      img {
        display: block;
      }
    }
    .app-extra {
      position: absolute;
      top: 15px;
      right: 14px;
      height: 0.46rem;
      line-height: 0.46rem;
      font-weight: normal;
      color: inherit;
    }
    // .app-close {
    //   width: 0.468rem;
    //   height: 0.46rem;
    //   background-image: url('../assets/icons/i-close-d.png');
    //   background-repeat: no-repeat;
    //   background-position: center center;
    //   background-size: 0.16rem;
    //   cursor: pointer;
    //   transition: all ease 0.2s;
    //   &:hover {
    //     @include background_color('theme-color-3');
    //     // background-color: $theme-color-3;
    //     background-image: url('../assets/icons/i-close-w.png');
    //   }
    // }
  }
  .header-content {
    padding-top: 20px;
    padding-right: 140px;
    padding-bottom: 22px;
    padding-left: $logo-size + 28px;
    font-size: 22px;
    @include font_color('theme-color-2');
    font-weight: bold;
    line-height: $logo-size;
    @include clear();

    .app-symbol {
      float: left;
      width: $logo-size;
      height: $logo-size;
      margin-left: -($logo-size + 20px);
      border-radius: 6px;
      overflow: hidden;
      text-align: center;
      position: relative;
      background-color: rgba(0, 64, 152, 0.2);
      img {
        display: block;
      }
    }
    .app-extra {
      position: absolute;
      top: 15px;
      right: 14px;
      height: 0.46rem;
      line-height: 0.46rem;
      font-weight: normal;
      color: inherit;
    }
    // .app-close {
    //   width: 0.468rem;
    //   height: 0.46rem;
    //   background-image: url('../assets/icons/i-close-d.png');
    //   background-repeat: no-repeat;
    //   background-position: center center;
    //   background-size: 0.16rem;
    //   cursor: pointer;
    //   transition: all ease 0.2s;
    //   &:hover {
    //     @include background_color('theme-color-3');
    //     // background-color: $theme-color-3;
    //     background-image: url('../assets/icons/i-close-w.png');
    //   }
    // }
  }
}
.common-util {
  font-size: 18px;
  @include font_color('font_color1');
  @include background_color('background_color1');
  @include border_color('border_color1');
}
</style>

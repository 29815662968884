<template>
  <div>
    <div class="poper-content-title">
      感谢您的反馈
      <i class="sys-model"></i>
    </div>
    <div class="poper-content__body">
      我们会努力给您更满意的答案您也可以选择留言或人工客服为您进一步解答
    </div>
    <div class="poper-content__footer">
      <!-- <div class="poper-button__default" @click="message">
        我要提问
      </div> -->
      <div class="poper-button__default" @click="confirm">
        转人工
      </div>
      <div class="poper-button__default btn-border" @click="cancle">
        不需要
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatFeedbackResponse',
  methods: {
    cancle () {
      this.$emit('cancle')
    },
    confirm () {
      this.$emit('confirm')
    }
    // message () {
    //   window.location.href =
    //     'http://rssmf.jsszhrss.gov.cn/web/weixin/comp/mgmt/f01s/f01s01/f01s0101.action'
    // }
  }
}
</script>

/**
 * Created by vonsh on 2019/3/26
 */
class staffResponseHandler {
  constructor (context) {
    this.cache = {}
    this.context = context
  }

  bindContext (context) {
    this.context = context
    return this
  }

  addRule (rule, handler) {
    const key = rule.toString()

    // 验证是否存在同样的规则
    if (this.cache[key]) {
      console.log('同名规则，用新规则覆盖旧规则')
    }
    this.cache[key] = {
      rule,
      handler
    }
  }

  removeRule (rule) {
    const key = rule.toString()

    // 验证是否存在同样的规则
    if (this.cache[key]) {
      delete this.cache[key]
    }
  }

  exec (origin, data, params) {
    var i
    var tester
    var handler

    for (i in this.cache) {
      if (Object.hasOwnProperty.call(this.cache, i)) {
        tester = this.cache[i].rule
        handler = this.cache[i].handler
        if (tester instanceof RegExp && tester.test(origin)) {
          handler.apply(this.context, arguments)
          return false
        }
        if (tester instanceof Function && tester(origin)) {
          handler.apply(this.context, arguments)
          return false
        }
      }
    }
  }
}

export default staffResponseHandler

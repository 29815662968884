import axios from 'axios'
import _ from 'lodash'
import cache from '@/plugins/storage'
import $store from '@/store'
const Fingerprint = require('@/plugins/fingerprint')
const io = window.io
const ENV = '!development' // 环境配置
let isConnect = false // 客服是否建立连接

/**
 * 2019-03-01 by wangs commit ID: #20190301_2.2
 * 更改人工客服自动断开时间有人工客服坐席控制，去除前台的时间控制
 * let delay = window.config.stuffService.offWait || 60;
 **/
const wsData = {
  userId: new Fingerprint().get(),
  appId: '',
  orgi: '',
  session: '',
  nickname: '',
  host: window.config.stuffService.host
}
let userData
let disconnectType = 0 // type: 0 客服人工手动关闭 1 系统定时器自动关闭 2 用户切换标准问操作自动切换智能客服
let StaffConnect
let connectFlag // 区分移动端自动断开事件 ‘system’自动断开，‘user’用户断开
let hasFeedbacked = false

const stringParams = data => {
  let i
  const ret = []

  for (i in data) {
    if (Object.hasOwnProperty.call(data, i)) {
      ret.push(i + '=' + data[i])
    }
  }
  return ret.join('&')
}

const getUrlRelativePath = url => {
  const arrUrl = url.split('//')
  const start = arrUrl[1].indexOf('/')
  let relUrl = arrUrl[1].substring(start)
  if (relUrl.indexOf('?') !== -1) {
    relUrl = relUrl.split('?')[0]
  }
  return relUrl
}

// 定时任务
export const microTask = (function () {
  let intervalTask = null
  let time = 0

  return function (between, cbk) {
    clearInterval(intervalTask)
    intervalTask = null
    time = +new Date()
    if (!between) {
      return false
    }
    intervalTask = setInterval(function () {
      const now = +new Date()

      if (now - time > between + 1000) {
        time = now
        return false
      }
      if (now - time > between - 1000) {
        cbk()
        clearInterval(intervalTask)
        intervalTask = null
      }
    }, 1000)
  }
})()

// 发送消息
export const post = (message) => {
  if (message !== '') {
    if (message === 'dev->close#4410') {
      disconnectType = 0
      StaffConnect.disconnect()
      return false
    }
    if ($store.state.chattingType !== 'staff') {
      disconnectType = 0
      StaffConnect.disconnect()
      return false
    }
    StaffConnect.emit('message', {
      appid: wsData.appId,
      userid: wsData.userId,
      type: 'message',
      session: wsData.session,
      orgi: wsData.orgi,
      message: message
    })
    // 创建定时器，固定时间后不发送消息自动断开链接
    /*
    commit ID: #20190301_2.2
    microTask(delay * 1000, function () {
      disconnectType = 1;
      StaffConnect.disconnect();
    });
    */
  }
}

// 创建socket链接
export const create = (reciever, stoper) => {
  if (isConnect) {
    return false
  }
  isConnect = true
  connectFlag = 'user'
  if (ENV === 'development') { // nodejs 模拟开发环境
    StaffConnect = io.connect('127.0.0.1:3000')
    StaffConnect.on('connect', function () {
      console.log('已连接.......')
    })
    StaffConnect.on('message', function (data) {
      if (data.calltype === 'out') { // 接受到的消息
        reciever(data, 'message')
      }
    })
    StaffConnect.on('status', function (data) {
      reciever(data, 'status')
    })
    StaffConnect.on('disconnect', function () {
      stoper(_.cloneDeep(wsData))
      console.log('聊天已断开')
      console.log('disconnected....')
    })
  } else {
    if (StaffConnect) {
      return false
    }
    userData = cache.get('user')
    console.log(userData)

    /* 生成id失败再生成一次 */
    wsData.userId = wsData.userId || new Fingerprint().get()
    // 创建链接
    axios.get(wsData.host + '?userid=' + wsData.userId + '&' + stringParams(userData)).then(data => {
      let message

      data = data.data
      wsData.orgi = data.orgi
      wsData.session = data.sessionid
      wsData.nickname = data.nickname
      wsData.userId = data.userid
      wsData.appId = data.appid

      console.log(wsData)

      if (data.code !== '200') {
        disconnectType = 2
        if (data.code === '901') { // 非工作时间
          message = data.msg
        }
        if (data.code === '401') { // 用户被添加黑名单
          message = '人工坐席忙，请稍后'
        }
        reciever({
          message: message
        }, 'status')
        $store.commit('switchRole', 'robot')
        isConnect = false
        StaffConnect = null
        connectFlag = null
        return false
      }

      // 通知全局$store是否需要用户返回客服满意度
      $store.commit('setStaffFeedback', data.satisfaction === '1')

      // 连接
      StaffConnect = io.connect(window.config.stuffService.socket + '?' +
        'userid=' + wsData.userId + '&' +
        'orgi=' + wsData.orgi + ' &' +
        'session=' + wsData.session + '&' +
        'appid=' + wsData.appId + '&' +
        'osname=&' +
        'browser=&' +
        'nickname=' + wsData.nickname + '&' +
        stringParams(userData), { path: getUrlRelativePath(window.config.stuffService.socket).replaceAll('/im/user', '') + '/socket.io' })
      console.log('============================')
      console.log(stringParams(userData))
      console.log('============================')
      StaffConnect.on('connect', function () {
        if (hasFeedbacked) {
          disconnectType = 0
          StaffConnect.disconnect()
        }
        console.log('已连接.......')
        // 创建定时器，固定时间后不发送消息自动断开链接
        /*
        commit ID: #20190301_2.2
        microTask(delay * 1000, function () {
          disconnectType = 1;
          StaffConnect.disconnect();
        });
        */
      })
      StaffConnect.on('agentstatus', function (data) {
        console.log(data.message)
      })
      //
      StaffConnect.on('status', function (data) {
        wsData.agentserviceid = data.agentserviceid
        if (data.messageType === 'end') {
          disconnectType = 0
          StaffConnect.disconnect()
        } else if (connectFlag === 'user') {
          reciever(data, 'status')
        }
      })
      StaffConnect.on('message', function (data) {
        if ((data.calltype === 'out' && connectFlag === 'user')) { // 接受到的消息
          reciever(data, 'message')
          // 创建定时器，固定时间后不发送消息自动断开链接
          /* commit ID: #20190301_2.2
          microTask(delay * 1000, function () {
            disconnectType = 1;
            StaffConnect.disconnect();
          }); */
        } else if (data.calltype === 'in' && !data.fromUser) {
          reciever(data, 'status')
        }
        /*
        * 正常用户消息发送，会通知一个in类型的消息，他含有fromUser和toUser字段
        * 排队到坐席在线咨询后通知一个in类型的消息，它不含有任何用户信息
        * 监听message，calltype为in分为排队状态信息和正常用户发送消息，通过fromUser信息区分对待
        * */
      })
      // 断开连接
      StaffConnect.on('disconnect', function () {
        isConnect = false
        stoper(disconnectType, _.cloneDeep(wsData))
        StaffConnect = null
        connectFlag = null
        console.log('聊天已断开')
        console.log('disconnected....')
      })
    }).catch(e => {
      console.log(e)
    })
  }
}

// 反馈人工客服意见
export const feedback = (data) => {
  const query = {}
  var i

  data.id = wsData.agentserviceid
  data.orgi = wsData.orgi

  for (i in data) {
    if (Object.hasOwnProperty.call(data, i)) {
      query[i] = data[i]
    }
  }
  hasFeedbacked = true
  setTimeout(function () {
    hasFeedbacked = false
  }, 1500)
  return axios.get(window.config.stuffService.feedback, {
    params: query
  })
}

// 关闭服务
export const close = () => {
  disconnectType = 2
  StaffConnect && StaffConnect.disconnect()
  isConnect = false
  StaffConnect = null
  // microTask();  // 停掉定时任务
}

export default {
  create,
  post,
  close,
  feedback
}

import request from './serve'
// 获取到机器人的配置
export const getRobotConfig = (data) => {
  return request({
    url: '/api/config',
    method: 'post',
    data
  })
}

// 获取默认配置的接口
export const initApp = (data) => {
  return request({
    url: '/api/robot/index',
    method: 'post',
    data
  })
}
export const wxConfig = (data) => {
  return request({
    url: '/api/wx/config?url=' + data,
    method: 'get'
  })
}

// 问答接口
export const askQuestion = (data) => {
  return request({
    url: '/api/robot/index',
    method: 'post',
    data
  })
}

// 输入联想
export const verbInput = (data) => {
  return request({
    url: '/api/knowledgeBase/searchQuestionByNlp',
    method: 'post',
    data
  })
}

// 获取热点问题
export const getHotList = (data) => {
  return request({
    url: '/api/knowledgeBase/hotQuestion',
    method: 'post',
    data
  })
}

// 反馈知识的是否有帮助
export const feedback = (data) => {
  return request({
    url: '/api/robot/useful',
    method: 'post',
    data
  })
}

// 退出当前的场景
export const exitScene = data => {
  return request({
    url: '/api/robot/toScene',
    method: 'post',
    data
  })
}

// 记录栏目导航
export const setNavigation = (data) => {
  return request({
    url: '/api/robot/setNavigation',
    method: 'post',
    data
  })
}

// 语音转文字的接口
export const audio2Txt = data => {
  return request({
    url: '/api/nls/asr',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data' // 含有附件的表单提交
    },
    transformRequest: [
      input => {
        // 把数据转成 formData 进行传输
        const formData = new FormData()
        formData.append('file', data.file)
        return formData
      }
    ]
  })
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import '@vant/touch-emulator'
import router from '@/router'
import './styles/vant.scss'
import './styles/theme/_handle.scss'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import preview from '@/components/ImagePreview'
import cache from '@/plugins/storage'
// import vConsole from 'vconsole'
import EvtBus from '@/plugins/evtBus'

Vue.use(VueCookies)
Vue.use(VueRouter)

// 引入样式
require('@/styles/reset.scss')
require('@/styles/base.scss')
Vue.config.$axios = axios

Vue.config.productionTip = false

Vue.prototype.env = window.PLATFORM
Vue.prototype.evtBus = EvtBus

// eslint-disable-next-line
// new vConsole()

// 先注册platform信息再初始化 vue app
import('./store')
  .then(({ default: store }) => {
    return new Promise((resolve) => {
      // 定义系统维护中，加载固定页面，路由注册的地址全部无效
      const compLoader = store.state.appConfig.isMaintaining
        ? import('./Maintain.vue')
        : import('./App.vue')
      compLoader.then(
        ({ default: app }) => {
          return resolve({
            store,
            app
          })
        },
        (err) => {
          console.log(err)
        }
      )
    })
  })
  .then(({ store, app }) => {
    new Vue({
      store,
      router,
      render: (h) => h(app),
      created () {
        store.dispatch('getRobotConfig')
      },
      mounted () {
        // 为所有的图片绑定处理事件，预览图片
        document.body.addEventListener(
          'click',
          function (e) {
            /**
             * 为对话框中的 img 委托事件查看大图
             */
            function isImgInPop (target) {
              if (target.tagName !== 'IMG') {
                return false
              }
              let isWrapperInPop = false
              if (target.getAttribute('data-preview')) {
                return true
              }
              let parent = target.parentNode
              while (parent) {
                if (
                  parent.className &&
                  parent.className.indexOf('text-message__txt') >= 0
                ) {
                  isWrapperInPop = true
                  break
                }
                parent = parent.parentNode
              }
              return isWrapperInPop
            }
            // 为连接到图片的超链接
            function isImagAnchor (target) {
              if (target.tagName.toLowerCase() !== 'a') {
                return false
              }
              const href = target.getAttribute('href')
              const imageTester = /.(png|jpg|jpeg|webp|gif)(\?.*)?$/i

              if (href && imageTester.test(href)) {
                return true
              }
              return false
            }
            const target = e.target
            if (isImgInPop(target)) {
              preview(target.getAttribute('src'))
            } else if (isImagAnchor(target)) {
              preview(target.getAttribute('href'))
              e.preventDefault()
            }
          },
          false
        ) // 事件捕获模型绑定事件

        // 获取缓存的用户数据，提交到store中
        const user = cache.get('user')
        if (user) {
          this.$store.commit('setUserData', user)
        }
        const uiMode = cache.get('uiMode')
        if (uiMode) {
          this.$store.commit('switchMode', uiMode)
        }
      }
    }).$mount('#app')
  })
